export default {
    zh:{
        piChange:{
            queryParam:{
                status:'变更单状态',
                status_placeholder:'请选择变更单状态',
                ysfs:'运输方式',
                ysfs_placeholder:'请输入运输方式',
            },
            columns:{
                F_JHRQ:'交货日期',
                F_YSFS:'运输方式',
                F_STATUS:'变更单状态',
            },
            form:{
                piId:'变更PI',
                piId_placeholder:'请选择变更PI',
                piId_rule: '请选择变更PI',
                jhrq:'交货日期',
                jhrq_placeholder:'请选择交货日期',
                jhrq_rule: '请选择交货日期',
                ysfs:'运输方式',
                ysfs_placeholder:'请输入运输方式',
                fktk:'付款条款',
                fktk_placeholder:'请输入付款条款',
                jgtk:'价格条款',
                jgtk_placeholder:'请输入价格条款',
            },
        }
    },
    en:{
        piChange:{
            queryParam:{
                status:'变更单状态',
                status_placeholder:'请选择变更单状态',
                ysfs:'运输方式',
                ysfs_placeholder:'请输入运输方式',
            },
            columns:{
                F_JHRQ:'交货日期',
                F_YSFS:'运输方式',
                F_STATUS:'变更单状态',
            },
            form:{
                piId:'变更PI',
                piId_placeholder:'请选择变更PI',
                piId_rule: '请选择变更PI',
                jhrq:'交货日期',
                jhrq_placeholder:'请选择交货日期',
                jhrq_rule: '请选择交货日期',
                ysfs:'运输方式',
                ysfs_placeholder:'请输入运输方式',
                fktk:'付款条款',
                fktk_placeholder:'请输入付款条款',
                jgtk:'价格条款',
                jgtk_placeholder:'请输入价格条款',
            },
        }
    }
}