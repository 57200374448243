import BaseBean from "@/utils/BaseBean";

export interface IPiChangeListDataObj {
    utilInst:PiChangeListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class PiChangeListUtil extends BaseBean{
    public dataObj:IPiChangeListDataObj

    constructor(proxy:any,dataObj:IPiChangeListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.otherParams.networkData=await this.utils.Api.buildNetworkData();
    }
}