import PiChangeCard from './PiChangeCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import PiChangeListUtil,{IPiChangeListDataObj} from './piChangeListUtil';
import language from './piChangeLanguage'
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
export default defineComponent ({
    name: 'PiChangeList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IPiChangeListDataObj=reactive<IPiChangeListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {
                    cardFrom:'PiChangeList'
                },
                cardFrom:'PiChangeList',
                modelComp: PiChangeCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/piChange/pageData')
            },
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PiChangeListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'status'==params.comboId){
                    return [
                        {value:0,label:'待提交'},
                        {value:1,label:'待大区经理审批'},
                        {value:2,label:'大区经理退回'},
                        {value:3,label:'待财务经理审批'},
                        {value:4,label:'财务经理退回'},
                        {value:5,label:'变更完成'}
                    ]
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }
        //查看pi弹出框
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:proxy,cardFrom:'PiChangeList',
                title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo,piDetail
        }
    }
});